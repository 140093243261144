<template>
  <el-container>
    <el-header>
      <el-link href="/" :underline="false">echo的个人空间</el-link>
    </el-header>
    <el-main>
      <div class="main_area">
        <div class="title">个人项目</div>
        <el-card v-for="(item,index) in projects" :key="index">
          {{ item.name }} : <a :href="item.link">{{item.link}}</a>
        </el-card>
      </div>
    </el-main>
    <el-footer>
      <el-link href="https://beian.miit.gov.cn/" :underline="false">辽ICP备2021012730号-1</el-link>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      projects: [
        {
          name: '智慧校园系统',
          link: 'https://echospace.xyz/smartcampus'
        },
        {
          name: '智慧校园后台管理系统',
          link: 'https://echospace.xyz/smartcampus_manage'
        },
        {
          name: '智慧校园后台API服务器',
          link: 'https://echospace.xyz/smartcampus_api'
        },
        {
          name: '流媒体推流测试页面',
          link: 'https://echospace.xyz/liveroom'
        },
        {
          name: 'RTMP测试服务器',
          link: 'https://echospace.xyz/rtmpstream'
        },
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.el-header {
  height: 80px !important;
  min-width: 900px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  .el-link {
    float: left;
    margin-left: 30px;
    height: 80px;
    line-height: 80px;
    font-size: 30px;
    color: black;
  }
  .el-link:hover {
    color: black;
  }
}

.el-main {
  width: 100%;
  .main_area {
    margin-top: 80px;
    padding: 0 5%;
    .title {
      font-size: 30px;
    }
    .el-card {
      margin-top: 30px;
    }
  }
}

.el-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  .el-link {
    font-size: 17px;
    font-weight: 700;
  }
}
</style>